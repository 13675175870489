import "./homepage.scss";
import third from "../images/photo5.jpg";
import second from "../images/photo6.jpg";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import React from "react";

import Header from "./header";
import Concept from "./concept";
import Planification from "./planification";
import Contact from "./contact";
import Events from "./events";

function Homepage() {
  return (
    <>
      <Header />
      <div className="quote">
        "It’s your event. Your dream. Our passion is to help make it yours in a
        way that you will remember for a lifetime. That’s a lot to ask of one
        day. But we can do this!"
      </div>
      <div className="rest-section">
        <Concept />
        <Planification />
        <Events />
        <span className="rest-section__title" id={"onorarii"}>
          ONORARII
        </span>
        <p className="rest-section__paragraph">
          Cere o ofertă de preț. Fiecare eveniment este diferit și tocmai din
          acest considerent oferta de preț este personalizată pentru fiecare
          client în parte.
        </p>
        <div className="rest-section-wrapper">
          <img
            className="rest-section__concept-image rest-section__concept-image--no-radius"
            src={third}
          />
          <img
            className="rest-section__concept-image rest-section__concept-image--no-radius"
            src={second}
          />
        </div>
        <span className="rest-section__title" id={"CONTACT"}>
          CONTACT
        </span>
        <Contact />
      </div>
    </>
  );
}

export default Homepage;
