import "../homepage.scss";
import mainImage from "../../images/coperta.jpg";
import Motto from "../../images/motto2.svg";
import mainLogo from "../../images/main-logo2.svg";

import "react-responsive-carousel/lib/styles/carousel.min.css";

import React from "react";

var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
};

function Header() {
  return (
    <div className="header">
      <div className="header-sub">
        <img className='header-logo' src={mainLogo} />
        <div className="header-motto">
          <img src={Motto} />
        </div>
      </div>
      <img className="header-image" src={mainImage} />
      <nav role="navigation" className="navigation">
        <div id="menuToggle">
          <input type="checkbox" />
          <div>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <ul id="menu">
            <a href="#concept">
              <li>CONCEPT</li>
            </a>
            <a href="#planificare">
              <li>PLANIFICARE</li>
            </a>
            <a href="#evenimente">
              <li>EVENIMENTE</li>
            </a>
            <a href="#onorarii">
              <li>ONORARII</li>
            </a>
            <a href="#contact">
              <li>CONTACT</li>
            </a>
          </ul>
        </div>
      </nav>
    </div>
  );
}

export default Header;
