import "../homepage.scss";

import "react-responsive-carousel/lib/styles/carousel.min.css";

import React from "react";

import { Carousel } from "react-responsive-carousel";
import CImage1 from "../../images/12.jpg";
import CImage2 from "../../images/35.jpg";
import CImage8 from "../../images/photo1.jpg";
import CImage9 from "../../images/photo2.jpg";
import CImage10 from "../../images/photo3.jpg";

function Events() {
  return (
    <div className="rest-section__events">
      <span className="rest-section__title" id={"evenimente"}>
        EVENIMENTE
      </span>
      <Carousel showThumbs={false}>
        <div className="header-secondary-image">
          <img src={CImage2} />
        </div>
        <div className="header-secondary-image">
          <img src={CImage1} />
        </div>
        <div className="header-secondary-image">
          <img src={CImage8} />
        </div>
        <div className="header-secondary-image">
          <img src={CImage9} />
        </div>
        <div className="header-secondary-image">
          <img src={CImage10} />
        </div>
      </Carousel>
    </div>
  );
}

export default Events;
