import "../homepage.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import React from "react";

function Planification() {
  return (
    <div className="rest-section__planification">
      <span className="rest-section__title" id={"planificare"}>
        PLANIFICARE
      </span>
      <p className="rest-section__paragraph">
        <div className="rest-section__paragraph-list">
          Fiecare eveniment începe cu o poveste. Cum? Cu un mic secret și acesta
          este ascultarea ca să întelegem ce iți dorești și cum visezi să arate
          totul. Clienți noștri sunt la fel de unici ca și evenimentele. Cum
          spun tot timpul: nu există limite, orice idee, oricât de nebunească ar
          fi, este posibilă, iar orice vă doriți cu siguranță are și o soluție.
          Totul se desfășoară în 4 pași simpli, care să vă ajute să lăsați la o
          parte toate grijile, iar sarcina organizării evenimentului să ne
          revină nouă.
        </div>
        <ul>
          <li className="menu">
            <div class="numberCircle">1</div>
            <span style={{ textAlign: "center" }}>
              După contactarea noastră o să primiți un document în care va
              trebui să selectați lucrurile la care vă gândiți că aveți nevoie.
              Trimitem acest formular pentru a facilita comunicarea, pentru a vă
              prezenta opțiuni pe care nu l-ați considerat până la acel moment
              și pentru conturarea unor idei despre felul în care doriți să
              arate evenimentul dumneavoastră.
            </span>
          </li>
          <li className="menu">
            <div class="numberCircle">2</div>
            <span style={{ textAlign: "center" }}>
              Stabilim o întâlnire și, ca orice poveste de prietenie, ne vom
              cunoaște și vom discuta toate ideile pe care le aveți, iar
              împreună vom alege variantele potrivite pentru evenimentul
              dumneavoastră. După ce am stabilit toate amănuntele, în 48h veți
              avea o ofertă personalizată, cu tot ce am discutat la întâlnire.
              Bugetul este un aspect foarte important al evenimentelor, iar
              acesta îl vom stabili, estimativ, împreună.
            </span>
          </li>
          <li className="menu">
            <div class="numberCircle">3</div>
            <span style={{ textAlign: "center" }}>
              Trecem la treabă! O să primiți periodic raportări cu privire la
              oferte de preț, furnizori contractați și distribuirea bugetului.
              Desigur, putem să facem acestea prin telefon, email sau întâlnire,
              este la latitudinea dumneavoastră.
            </span>
          </li>
          <li className="menu">
            <div class="numberCircle">4</div>
            <span style={{ textAlign: "center" }}>
              Ne apropiem de data evenimentului și o să avem o întâlnire pentru
              a stabili ultimele detalii (ex. programul evenimentului). De
              asemenea, în ziua evenimentului am să fiu acolo, iar dumneavoastră
              vă veți putea bucura de toate emoțiile și persoanele importante
              care vă sunt alături. Ne vedem la eveniment cu multă energie și
              zâmbete!
            </span>
          </li>
        </ul>
      </p>
    </div>
  );
}

export default Planification;
