import "../homepage.scss";
import React from "react";

function Contact() {
  return (
    <div className="rest-section__contact">
      <form class="form">
        <input class="form_input" type="text" placeholder="Nume" />
        <input class="form_input" type="email" placeholder="Adresa Email" />
        <button class="form_submit" type="button">
          Trimite
        </button>
      </form>
      <div className="contact-details ">
        <span>NUMĂR DE TELEFON - 0742420467</span>
        <span>EMAIL- events@cristinamilas.ro</span>
        <span>PAGINA INSTAGRAM - @milas.cristina </span>
        <span>PAGINA FACEBOOK- Milas Cristina Event Planner</span>
      </div>
    </div>
  );
}

export default Contact;
