import "../homepage.scss";
import third from "../../images/34.jpg";

import "react-responsive-carousel/lib/styles/carousel.min.css";

import React from "react";


function Concept() {
  return (
    <div className="rest-section__concept">
      <div className="rest-section__concept-text" id={"concept"}>
        <span className="rest-section__title"> CONCEPT </span>
        <p className="rest-section__paragraph">
          Munca unui event planner înseamnă planificarea și coordonarea fiecărui
          aspect, de la invitați, decorațiuni, buget până la ultimul detaliu de
          care este nevoie în ziua evenimentului. Unui event planner îi revine
          și sarcina de a se asigura că personalitatea fiecărui client se
          regăsește în evenimentul acestuia. Pe lângă organizarea de nunți, ne
          ocupăm si de evenimente private, conferințe și corporate. Scopul
          nostru este să organizăm evenimente unice care te capturează cu emoții
          și, cel mai important, cu fericire și iubire. Evenimentele sunt la fel
          de unice precum sunt și clienții noștri.
        </p>
      </div>
      <img className="rest-section__concept-image" src={third} />
    </div>
  );
}

export default Concept;
